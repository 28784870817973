import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListParamsService {

  // Método para guardar los parámetros
  saveParams(key: string, params: any) {
    localStorage.setItem(key, JSON.stringify(params));
  }

  // Método para recuperar los parámetros
  getParams(key: string): any {
    const params = localStorage.getItem(key);
    return params ? JSON.parse(params) : null;
  }

  // Método para eliminar los parámetros
  clearParams(key: string) {
    localStorage.removeItem(key);
  }
}
