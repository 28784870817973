import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SidebarComponent } from './modules/shared/sidebar/sidebar.component';

const routes: Routes = [

  {
    path: '',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'micrositio',
    loadChildren: () => import('./modules/microsite/microsite.module').then(m => m.MicrositeModule)
  },
  {
    path: 'propuestas',
    loadChildren: () => import('./modules/proposal-gallery/proposal-gallery.module').then(m => m.ProposalGalleryModule)
  },
  {
    path: 'consulta',
    loadChildren: () => import('./modules/microsite-query/microsite-query.module').then(m => m.MicrositeQueryModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
