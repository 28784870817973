import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ListParamsService } from './list-params.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private routeKeyMap = new Map<string, string>([
    ['/home/accounts', 'customerList'],
    ['/home/request/list', 'requestList'],
    ['/home/request/myRequest', 'myRequestList'],
    ['/home/request/applicant/list', 'applicantList'],
    ['/home/sympathizer', 'sympathizerList'],
    ['/home/proposals', 'proposalList'],
    ['/home/POI', 'poiList'],
    ['/home/beneficiaries/list', 'beneficiaryList'],
    ['/home/beneficiaries/accomplishedList', 'accomplishedList'],
    ['/home/catalog', 'instanceList'],
    ['/home/geographic-frame', 'municipalityList'],
    // Agrega más rutas y claves según sea necesario
  ]);

  constructor(private router: Router, private listParamsService: ListParamsService) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart)
        this.handleNavigation(event.url);
    });
  }

  private handleNavigation(url: string) {
    this.routeKeyMap.forEach((_, route) => {
      if (!url.startsWith(route)) {
        const key = this.routeKeyMap.get(route);
        if (key)
          this.listParamsService.clearParams(key);
      }
    });
  }
}
